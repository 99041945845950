/* eslint-disable no-case-declarations */
/* eslint-disable class-methods-use-this */
import { BLOCK_TYPE, INLINE_STYLE, getEntityRanges } from 'draft-js-utils'
import { convertFromRaw } from 'draft-js'

const { BOLD, ITALIC, STRIKETHROUGH, UNDERLINE } = INLINE_STYLE

class StateToPdfMake {
  constructor(contentState) {
    this.contentState = convertFromRaw(contentState)
    this.currentBlock = 0
    this.output = { content: [] }
    this.blocks = null
    this.listOlAcc = []
    this.listUlAcc = []
  }

  generate() {
    this.blocks = this.contentState.getBlockMap().toArray()

    while (this.currentBlock < this.blocks.length) {
      this.processBlock()
    }

    return this.output
  }

  processBlock() {
    const block = this.blocks[this.currentBlock]
    const defaultHeaderStyle = { bold: true, margin: [0, 5, 0, 0] }

    if (
      block.getType() !== BLOCK_TYPE.UNORDERED_LIST_ITEM &&
      !!this.listUlAcc.length
    ) {
      this.updateAndResetUlList()
    }
    if (
      block.getType() !== BLOCK_TYPE.ORDERED_LIST_ITEM &&
      !!this.listOlAcc.length
    ) {
      this.updateAndResetOlList()
    }

    switch (block.getType()) {
      case BLOCK_TYPE.HEADER_ONE:
        this.output.content.push({
          text: block.getText(),
          lineHeight: 1.5,
          fontSize: 24,
          ...defaultHeaderStyle
        })
        break
      case BLOCK_TYPE.HEADER_TWO:
        this.output.content.push({
          text: block.getText(),
          fontSize: 22,
          lineHeight: 1.5,
          ...defaultHeaderStyle
        })
        break
      case BLOCK_TYPE.HEADER_THREE:
        this.output.content.push({
          text: block.getText(),
          fontSize: 20,
          lineHeight: 1.5,
          ...defaultHeaderStyle
        })
        break
      case BLOCK_TYPE.HEADER_FOUR:
        this.output.content.push({
          text: block.getText(),
          fontSize: 18,
          lineHeight: 1.5,
          ...defaultHeaderStyle
        })
        break
      case BLOCK_TYPE.HEADER_FIVE:
        this.output.content.push({
          text: block.getText(),
          fontSize: 16,
          lineHeight: 1.5,
          ...defaultHeaderStyle
        })
        break
      case BLOCK_TYPE.HEADER_SIX:
        this.output.content.push({
          text: block.getText(),
          fontSize: 14,
          lineHeight: 0.5,
          ...defaultHeaderStyle
        })
        break
      case BLOCK_TYPE.ORDERED_LIST_ITEM:
        this.listOlAcc.push({ text: [...this.renderBlockContent(block)] })
        break
      case BLOCK_TYPE.UNORDERED_LIST_ITEM:
        this.listUlAcc.push({ text: [...this.renderBlockContent(block)] })
        break
      default:
        const data = this.renderBlockContent(block)
        this.output.content.push(
          data.length ? { text: [...data] } : { text: '\n' }
        )
    }

    if (block.getKey() === this.contentState.getLastBlock().getKey()) {
      if (this.listUlAcc.length) {
        this.updateAndResetUlList()
      }

      if (this.listOlAcc.length) {
        this.updateAndResetOlList()
      }
    }

    this.currentBlock += 1
  }

  renderBlockContent(block) {
    if (block.getText() === '') {
      return []
    }

    const ranges = getEntityRanges(block.getText(), block.getCharacterList())

    return ranges.reduce((acc, [entityKey, stylePieces]) => {
      acc.push(
        ...stylePieces
          .map(([text, style]) => ({
            text: this.encodeContent(text),
            bold: style.has(BOLD),
            italics: style.has(ITALIC),
            decoration: this.getTextDecorations(style)
          }))
          .filter(properties => properties.text !== ' ')
      )

      return acc
    }, [])
  }

  getTextDecorations(style) {
    const object = { [UNDERLINE]: 'underline', [STRIKETHROUGH]: 'lineThrough' }

    return Object.keys(object).reduce((acc, key) => {
      if (style.has(key)) {
        acc.push(object[key])
      }

      return acc
    }, [])
  }

  encodeContent(text) {
    return text.replace(/[*_`]/g, '\\$&')
  }

  updateAndResetUlList() {
    this.output.content.push({ ul: this.listUlAcc })
    this.listUlAcc = []
  }

  updateAndResetOlList() {
    this.output.content.push({ ol: this.listOlAcc })
    this.listOlAcc = []
  }
}

export default StateToPdfMake
