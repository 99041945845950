import { useState, useEffect } from 'react'

const getWindowDimensions = () => {
  const { innerWidth, innerHeight } = window
  return {
    width: innerWidth,
    height: innerHeight
  }
}

const getIsMobileDimensions = windowDimensions => {
  const { width } = windowDimensions
  return width <= 900
}

const getIsTabletDimensions = windowDimensions => {
  const { width } = windowDimensions
  return !getIsMobileDimensions(windowDimensions) && width < 960
}

const getIsDesktopDimensions = windowDimensions =>
  !getIsMobileDimensions(windowDimensions) &&
  !getIsTabletDimensions(windowDimensions)

export default () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )
  const [isMobile, setIsMobile] = useState(
    getIsMobileDimensions(windowDimensions)
  )
  const [isTablet, setIsTablet] = useState(
    getIsTabletDimensions(windowDimensions)
  )
  const [isDesktop, setIsDesktop] = useState(
    getIsDesktopDimensions(windowDimensions)
  )

  useEffect(() => {
    const handleResize = () => {
      const dimensions = getWindowDimensions()
      setWindowDimensions(dimensions)
      setIsMobile(getIsMobileDimensions(dimensions))
      setIsTablet(getIsTabletDimensions(dimensions))
      setIsDesktop(getIsDesktopDimensions(dimensions))
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    ...windowDimensions,
    windowDimensions,
    isMobile,
    isTablet,
    isDesktop
  }
}
