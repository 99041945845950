import React, { Suspense, lazy, useMemo } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { LastLocationProvider } from 'react-router-last-location'
// import SelectOrganisation from 'src/components/SelectOrganisation'
// import IdControl from 'src/pages/IdControl/IdControl'
// import ControlDetails from 'src/pages/IdControl/ControlDetailsContainer'
import { OrganisationServices } from 'src/utils/constants'
// import DigitalIdentification from 'src/components/DigitalIdentification'
// import Reports from 'src/pages/Reports/Reports'
// import ServiceRequests from 'src/pages/Apartments/ServiceRequests/ServiceRequestList'
import { history } from './redux/store'
// import Dashboard from './pages/Dashboard/Dashboard'
// import NotFound from './pages/NotFound/Container'
// import PrivateLayoutV2 from './layouts/PrivateLayout/ContainerV2'
// import PublicLayout from './layouts/PublicLayout/Container'
// import Properties from './pages/Properties/Container'
// import PropertyDetail from './pages/Properties/PropertyDetail/Container'
// import UserList from './pages/Users/UserListContainer'
// import UserDetail from './pages/Users/UserDetailContainer'
// import UserPasswordUpdate from './pages/Users/UserPasswordUpdate'
// import Cases from './pages/Cases/Cases'
// import NewCase from './pages/Cases/NewCase/NewCase'
// import NewOrder from './pages/Cases/Order'
// import CaseDetails from './pages/Cases/CaseDetails/CaseDetails'
// import Documents from './pages/Documents/Documents'
// import DocumentDetailMobile from './pages/Documents/DocumentDetail/DocumentDetailMobile'
// import Admin from './pages/Admin/Container'
// import SuccessAddPartner from './pages/Admin/success/AddPartner'
// import SuccessAddCustomer from './pages/Admin/success/AddCustomer'
// import SuccessAddUser from './pages/Admin/success/AddUser'
// import AdminDetail from './pages/Admin/AdminDetailContainer'
// import Register from './pages/Register/Container'
// import Login from './pages/Login/Container'
// import Recover from './pages/Recover/Container'
// import Reset from './pages/Reset/Container'
// import LockDetail from './pages/Locks/Detail/LockDetail'
// import CustomizedSnackbar from './common/Snackbar/Container'
// import Logout from './pages/Logout/Logout'
// import BlueprintWizard from './components/BlueprintWizard/Container'
// import BlueprintWizardContainer from './pages/Blueprint/BlueprintWizardContainer'
// import BlueprintTool from './components/BlueprintTool/Container'
// import RegisterOnboarding from './pages/RegisterOnboarding/Container'
// import BlueprintListView from './pages/Blueprint/BlueprintListView'
// import QuoteRequest from './pages/QuoteRequest/QuoteRequest'
// import QuoteRequestSuccess from './pages/QuoteRequest/QuoteRequestSuccess'
// import DelegateLockAccess from './pages/MyLocks/Share/CreateDelegatedAccess'
// import DelegatedAccessDetails from './pages/MyLocks/Share/Details'
// import MyLocks from './pages/MyLocks/MyLocks'
// import Access from './pages/DelegatedAccess/Access'
// import SingleDelegatedLock from './pages/DelegatedAccess/SingleDelegatedLock'
// import SingleLock from './pages/MyLocks/SingleLock'
// import DelegatedAccessList from './pages/MyLocks/DelegatedAccessList'
// import LockEvents from './pages/MyLocks/Events'
// import DeviceDetail from './pages/Devices/DeviceDetail/DeviceDetail'
// import Devices from './pages/Devices/Devices'
// import AccessGroup from './pages/Admin/AccessGroups/AccessGroup'
// import AccessGroupItems from './pages/Admin/AccessGroups/AccessGroupItems'
// import MyOrganisation from './pages/Organisation/MyOrganisation'
// import MobileNavigationPage from './pages/Admin/AccessGroups/MobileNavigationPage'
// import AccessByItem from './pages/Admin/AccessGroups/AccessByItem'
// import MyKeys from './pages/Keys/MyKeys'
// import Booking from './pages/Booking/Booking'
// import KeyReceiptAndInventoryRequest from './pages/Keys/KeyReceiptAndInventoryRequest'
// import Keys from './pages/Keys/Keys'
// import KeyDetails from './pages/Keys/KeyDetails/KeyDetails'
// import Repositories from './pages/Keys/Repositories'
// import KeysList from './pages/Keys/KeysList'
// import PaymentDetails from './pages/Keys/Order/PaymentDetails'
// import OrderCheckout from './pages/Keys/Order/Checkout'
// import KeyImportPreview from './pages/Keys/Import/Keys/Preview'
// import UserImportPreview from './pages/Admin/UserImport/Preview'
// import ApartmentImportPreview from './pages/Admin/ApartmentImport/Preview'
// import ReceiptTemplatesDetails from './pages/Keys/ReceiptTemplatesDetails'
// import ReceiptTemplates from './pages/Keys/ReceiptTemplatesList'
// import KeyRingsList from './pages/Keys/KeyRings/KeyRingsList'
// import KeyRingKeys from './pages/Keys/KeyRings/KeyRingKeys'
// import Inventory from './pages/Keys/Inventory/Inventory'
// import NewKeyInventoryView from './pages/Keys/Inventory/NewKeyInventoryView'
// import KeyInventoryDetailView from './pages/Keys/Inventory/KeyInventoryDetailView'
// import KeyInventoryManualVerificationView from './pages/Keys/Inventory/KeyInventoryManualVerificationView'
// import LockSystems from './pages/Keys/LockSystems'
// import KeyLoanGuide from './pages/Keys/Loans/Guide'
// import KeySettings from './pages/Keys/Settings'
// import PublicTemporaryKeyLoan from './pages/Keys/Loans/Public/TemporaryKeyLoan'
// import KeeHistory from './pages/Devices/KeeHistory'
// import ReturnKeys from './pages/Keys/Loans/ReturnKeys'
// import AdminSettingsOrders from './pages/Admin/Settings/Orders'
// import ActivateAccount from './pages/Login/ActivateAccount'
// import ApartmentUsers from './pages/Apartments/ApartmentUsers'
// import Contact from './pages/Contact/Contact'
import HeaderWithStyles from './components/Header'
import { Menu } from '@material-ui/icons'
import { CircularProgress, Skeleton } from '@mui/material'
import useWindowDimensions from './utils/useWindowDimensions'

const DigitalIdentification = lazy(() =>
  import('src/components/DigitalIdentification')
)
const SelectOrganisation = lazy(() =>
  import('src/components/SelectOrganisation')
)
const ServiceRequests = lazy(() =>
  import('src/pages/Apartments/ServiceRequests/ServiceRequestList')
)

const Reports = lazy(() => import('src/pages/Reports/Reports'))
const IdControl = lazy(() => import('src/pages/IdControl/IdControl'))
const ControlDetails = lazy(() =>
  import('src/pages/IdControl/ControlDetailsContainer')
)

const PublicLayout = lazy(() => import('src/layouts/PublicLayout/Container'))
const PrivateLayoutV2 = lazy(() =>
  import('src/layouts/PrivateLayout/ContainerV2')
)

const NewServiceRequest = lazy(() =>
  import('src/pages/Apartments/ServiceRequests/NewServiceRequest')
)
const ServiceRequestDetail = lazy(() =>
  import('src/pages/Apartments/ServiceRequests/ServiceRequestDetail')
)

const UserList = lazy(() => import('src/pages/Users/UserListContainer'))
const UserDetail = lazy(() => import('src/pages/Users/UserDetailContainer'))
const UserPasswordUpdate = lazy(() =>
  import('src/pages/Users/UserPasswordUpdate')
)
const Dashboard = lazy(() => import('src/pages/Dashboard/Dashboard'))
const NotFound = lazy(() => import('src/pages/NotFound/Container'))
const Properties = lazy(() => import('src/pages/Properties/Container'))
const PropertyDetail = lazy(() =>
  import('src/pages/Properties/PropertyDetail/Container')
)

const Cases = lazy(() => import('src/pages/Cases/Cases'))
const NewCase = lazy(() => import('src/pages/Cases/NewCase/NewCase'))
const NewOrder = lazy(() => import('src/pages/Cases/Order'))
const CaseDetails = lazy(() =>
  import('src/pages/Cases/CaseDetails/CaseDetails')
)

const Documents = lazy(() => import('src/pages/Documents/Documents'))
const DocumentDetailMobile = lazy(() =>
  import('src/pages/Documents/DocumentDetail/DocumentDetailMobile')
)

const Admin = lazy(() => import('src/pages/Admin/Container'))
const SuccessAddPartner = lazy(() =>
  import('src/pages/Admin/success/AddPartner')
)
const SuccessAddCustomer = lazy(() =>
  import('src/pages/Admin/success/AddCustomer')
)
const SuccessAddUser = lazy(() => import('src/pages/Admin/success/AddUser'))
const AdminDetail = lazy(() => import('src/pages/Admin/AdminDetailContainer'))
const Register = lazy(() => import('src/pages/Register/Container'))
const Login = lazy(() => import('src/pages/Login/Container'))
const Recover = lazy(() => import('src/pages/Recover/Container'))

const Reset = lazy(() => import('src/pages/Reset/Container'))
const LockDetail = lazy(() => import('src/pages/Locks/Detail/LockDetail'))
const CustomizedSnackbar = lazy(() => import('src/common/Snackbar/Container'))
const Logout = lazy(() => import('src/pages/Logout/Logout'))
const BlueprintWizard = lazy(() =>
  import('src/components/BlueprintWizard/Container')
)
const BlueprintWizardContainer = lazy(() =>
  import('src/pages/Blueprint/BlueprintWizardContainer')
)
const BlueprintTool = lazy(() =>
  import('src/components/BlueprintTool/Container')
)
const RegisterOnboarding = lazy(() =>
  import('src/pages/RegisterOnboarding/Container')
)
const BlueprintListView = lazy(() =>
  import('src/pages/Blueprint/BlueprintListView')
)
const QuoteRequest = lazy(() => import('src/pages/QuoteRequest/QuoteRequest'))
const QuoteRequestSuccess = lazy(() =>
  import('src/pages/QuoteRequest/QuoteRequestSuccess')
)
const DelegateLockAccess = lazy(() =>
  import('src/pages/MyLocks/Share/CreateDelegatedAccess')
)
const DelegatedAccessDetails = lazy(() =>
  import('src/pages/MyLocks/Share/Details')
)
const MyLocks = lazy(() => import('src/pages/MyLocks/MyLocks'))
const Access = lazy(() => import('src/pages/DelegatedAccess/Access'))
const SingleDelegatedLock = lazy(() =>
  import('src/pages/DelegatedAccess/SingleDelegatedLock')
)
const SingleLock = lazy(() => import('src/pages/MyLocks/SingleLock'))
const DelegatedAccessList = lazy(() =>
  import('src/pages/MyLocks/DelegatedAccessList')
)
const LockEvents = lazy(() => import('src/pages/MyLocks/Events'))
const DeviceDetail = lazy(() =>
  import('src/pages/Devices/DeviceDetail/DeviceDetail')
)
const Devices = lazy(() => import('src/pages/Devices/Devices'))
const AccessGroup = lazy(() =>
  import('src/pages/Admin/AccessGroups/AccessGroup')
)
const AccessGroupItems = lazy(() =>
  import('src/pages/Admin/AccessGroups/AccessGroupItems')
)
const MyOrganisation = lazy(() =>
  import('src/pages/Organisation/MyOrganisation')
)
const MobileNavigationPage = lazy(() =>
  import('src/pages/Admin/AccessGroups/MobileNavigationPage')
)
const AccessByItem = lazy(() =>
  import('src/pages/Admin/AccessGroups/AccessByItem')
)
const MyKeys = lazy(() => import('src/pages/Keys/MyKeys'))
const Booking = lazy(() => import('src/pages/Booking/Booking'))
const KeyReceiptAndInventoryRequest = lazy(() =>
  import('src/pages/Keys/KeyReceiptAndInventoryRequest')
)
const Keys = lazy(() => import('src/pages/Keys/Keys'))
const KeyDetails = lazy(() => import('src/pages/Keys/KeyDetails/KeyDetails'))
const Repositories = lazy(() => import('src/pages/Keys/Repositories'))
const KeysList = lazy(() => import('src/pages/Keys/KeysList'))
const PaymentDetails = lazy(() => import('src/pages/Keys/Order/PaymentDetails'))
const OrderCheckout = lazy(() => import('src/pages/Keys/Order/Checkout'))
const KeyImportPreview = lazy(() =>
  import('src/pages/Keys/Import/Keys/Preview')
)
const UserImportPreview = lazy(() =>
  import('src/pages/Admin/UserImport/Preview')
)
const ApartmentImportPreview = lazy(() =>
  import('src/pages/Admin/ApartmentImport/Preview')
)
const ReceiptTemplatesDetails = lazy(() =>
  import('src/pages/Keys/ReceiptTemplatesDetails')
)
const ReceiptTemplates = lazy(() =>
  import('src/pages/Keys/ReceiptTemplatesList')
)
const KeyRingsList = lazy(() => import('src/pages/Keys/KeyRings/KeyRingsList'))
const KeyRingKeys = lazy(() => import('src/pages/Keys/KeyRings/KeyRingKeys'))
const Inventory = lazy(() => import('src/pages/Keys/Inventory/Inventory'))
const NewKeyInventoryView = lazy(() =>
  import('src/pages/Keys/Inventory/NewKeyInventoryView')
)
const KeyInventoryDetailView = lazy(() =>
  import('src/pages/Keys/Inventory/KeyInventoryDetailView')
)
const KeyInventoryManualVerificationView = lazy(() =>
  import('src/pages/Keys/Inventory/KeyInventoryManualVerificationView')
)
const LockSystems = lazy(() => import('src/pages/Keys/LockSystems'))
const KeyLoanGuide = lazy(() => import('src/pages/Keys/Loans/Guide'))
const KeySettings = lazy(() => import('src/pages/Keys/Settings'))
const PublicTemporaryKeyLoan = lazy(() =>
  import('src/pages/Keys/Loans/Public/TemporaryKeyLoan')
)
const KeeHistory = lazy(() => import('src/pages/Devices/KeeHistory'))
const ReturnKeys = lazy(() => import('src/pages/Keys/Loans/ReturnKeys'))
const AdminSettingsOrders = lazy(() =>
  import('src/pages/Admin/Settings/Orders')
)
const ActivateAccount = lazy(() => import('src/pages/Login/ActivateAccount'))
const ApartmentUsers = lazy(() => import('src/pages/Apartments/ApartmentUsers'))
const Contact = lazy(() => import('src/pages/Contact/Contact'))

// import NewServiceRequest from 'src/pages/Apartments/ServiceRequests/NewServiceRequest'
// import ServiceRequestDetail from 'src/pages/Apartments/ServiceRequests/ServiceRequestDetail'

//
const PrivateLayoutSkeleton = React.memo(() => {
  const { isMobile } = useWindowDimensions()
  if (isMobile) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
          justifyContent: 'space-between',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgb(240, 240, 240)'
        }}
      >
        <div
          style={{
            display: 'flex',
            marginTop: 'auto',
            width: '100%',
            height: 64,
            backgroundColor: 'rgb(27, 28, 26)'
          }}
        />
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </div>
        <div
          style={{
            display: 'flex',
            marginTop: 'auto',
            width: '100%',
            height: 90,
            backgroundColor: 'rgb(27, 28, 26)'
          }}
        />
      </div>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flex: 1
      }}
    >
      <div
        style={{
          width: '86px',
          height: '100vh',
          zIndex: 1011,
          backgroundColor: 'white',
          boxShadow:
            '0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12)'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20
          }}
        >
          <Menu
            style={{
              fontSize: 24,
              color: 'rgb(26, 28, 26)',
              marginRight: 4,
              marginTop: 2
            }}
          />
        </div>
      </div>

      <HeaderWithStyles left={80} />
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          top: 80,
          left: 84,
          right: 0,
          bottom: 0
        }}
      >
        <Skeleton
          variant='text'
          width='300px'
          height='42px'
          style={{ marginTop: 80, marginLeft: 40 }}
        />
        <div
          style={{
            marginTop: 40,
            marginLeft: 40,
            width: '90%',
            backgroundColor: 'white',
            marginRight: 80,
            height: 500,
            padding: 20,
            boxShadow:
              'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px'
          }}
        >
          <Skeleton
            variant='text'
            width='300px'
            height='42px'
            style={{ marginTop: 80, marginLeft: 80 }}
          />
        </div>
      </div>
    </div>
  )
})

const Routes = () => (
  <>
    <CustomizedSnackbar />
    <ConnectedRouter history={history}>
      <Suspense fallback={<PrivateLayoutSkeleton />}>
        <LastLocationProvider>
          <Switch>
            <Redirect exact path='/' to='/dashboard' />
            <PublicLayout
              exact
              path='/eident/return'
              component={DigitalIdentification}
              publicOnly={false}
            />
            <PrivateLayoutV2 exact path='/dashboard' component={Dashboard} />
            <PrivateLayoutV2
              exact
              path='/properties'
              component={Properties}
              scope={OrganisationServices.PROPERTIES}
            />
            <PrivateLayoutV2
              exact
              path='/properties/overview/:organisationId'
              component={BlueprintListView}
              scope={OrganisationServices.PROPERTIES}
              fullWidthContainer
            />
            <PrivateLayoutV2
              exact
              path='/properties/:propertyId'
              component={PropertyDetail}
              scope={OrganisationServices.PROPERTIES}
            />
            <PrivateLayoutV2
              exact
              path='/properties/:propertyId/overview'
              component={BlueprintListView}
              scope={OrganisationServices.PROPERTIES}
              fullWidthContainer
            />
            <PrivateLayoutV2
              exact
              path='/properties/:propertyId/quoteoverview'
              component={BlueprintListView}
              scope={OrganisationServices.PROPERTIES}
              fullWidthContainer
            />
            <PrivateLayoutV2
              exact
              path='/properties/:propertyId/quoteoverview/quoterequest'
              component={QuoteRequest}
              scope={OrganisationServices.PROPERTIES}
              fullWidthContainer
            />
            <PrivateLayoutV2
              exact
              path='/properties/:propertyId/quoteoverview/quoterequest/success'
              component={QuoteRequestSuccess}
              scope={OrganisationServices.PROPERTIES}
              fullWidthContainer
            />
            <PrivateLayoutV2
              exact
              path='/properties/:propertyId/blueprints/upload'
              component={BlueprintWizardContainer}
              scope={OrganisationServices.PROPERTIES}
              fullWidthContainer
            />
            <PrivateLayoutV2
              exact
              path='/properties/:propertyId/blueprints/:blueprintId'
              component={BlueprintTool}
              scope={OrganisationServices.PROPERTIES}
              fullWidthContainer
            />
            <PrivateLayoutV2
              exact
              path='/properties/:propertyId/blueprints/:blueprintId/overview'
              component={BlueprintListView}
              scope={OrganisationServices.PROPERTIES}
              fullWidthContainer
            />
            <PrivateLayoutV2
              exact
              path='/admin/accessgroups/:organisationId/mob/:accessgroupId'
              component={MobileNavigationPage}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/admin/accessgroups/nav'
              component={MobileNavigationPage}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/admin/accessgroups/accessbyitem/:organisationId/:type(users|locks)/:id'
              component={AccessByItem}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/admin/accessgroups/details/:organisationId/:groupId'
              component={AccessGroup}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/admin/accessgroups/new'
              component={AccessGroup}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/admin/accessgroups/:organisationId/:accessgroupId/:type(users|locks)'
              component={AccessGroupItems}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2 exact path='/users' component={UserList} />
            <PrivateLayoutV2 exact path='/myaccount' component={UserDetail} />
            <PrivateLayoutV2
              exact
              path='/myaccount/passwordupdate'
              component={UserPasswordUpdate}
            />
            {/* <PublicLayout exact path='/access' component={Access} publicOnly={false} /> */}
            <PublicLayout
              exact
              path='/access/:lockId(\d+)'
              component={SingleDelegatedLock}
              publicOnly={false}
            />
            <PublicLayout
              exact
              path='/access/:delegatedAccessUserUuid'
              component={Access}
              publicOnly={false}
            />
            <PrivateLayoutV2
              exact
              path='/mylocks'
              component={MyLocks}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/mylocks/share/list'
              component={DelegatedAccessList}
              scope={OrganisationServices.KEE}
            />
            {/* Creating / Sharing a lock */}
            <PrivateLayoutV2
              exact
              path='/mylocks/share/:lockId?'
              component={DelegateLockAccess}
              scope={OrganisationServices.KEE}
            />
            {/* Viewing details about a delegated access */}
            <PrivateLayoutV2
              exact
              path='/mylocks/access/:delegatedAccessId'
              component={DelegatedAccessDetails}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/mylocks/events'
              component={LockEvents}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/mylocks/:lockId'
              component={SingleLock}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/cases'
              component={Cases}
              scope={OrganisationServices.CASES}
            />
            <PrivateLayoutV2
              exact
              path='/cases/select-organisation'
              component={SelectOrganisation}
              scope={OrganisationServices.CASES}
            />
            <PrivateLayoutV2
              exact
              path='/booking/select-organisation'
              component={SelectOrganisation}
              scope={OrganisationServices.BOOKING}
            />
            <PrivateLayoutV2
              exact
              path='/cases/:type(claims|feedback|unread|orders)'
              component={Cases}
              scope={OrganisationServices.CASES}
            />
            <PrivateLayoutV2
              exact
              path='/cases/new'
              component={NewCase}
              scope={OrganisationServices.CASES}
            />
            <PrivateLayoutV2
              exact
              path='/cases/:id'
              component={CaseDetails}
              scope={OrganisationServices.CASES}
            />
            <PrivateLayoutV2
              exact
              path='/documents'
              component={Documents}
              scope={OrganisationServices.DOCUMENTS}
            />
            <PrivateLayoutV2
              exact
              path='/documents/:id'
              component={DocumentDetailMobile}
              scope={OrganisationServices.DOCUMENTS}
            />
            <PrivateLayoutV2
              exact
              path='/admin/settings/orders'
              component={AdminSettingsOrders}
            />
            <PrivateLayoutV2
              exact
              path='/admin/partners/success/:partnerId'
              component={SuccessAddPartner}
            />
            <PrivateLayoutV2
              exact
              path='/admin/partners/:partnerId/success/:customerId'
              component={SuccessAddCustomer}
            />
            <PrivateLayoutV2
              exact
              path='/admin/customers/success/:customerId'
              component={SuccessAddCustomer}
            />
            <PrivateLayoutV2
              exact
              path='/admin/users/import'
              component={UserImportPreview}
            />
            <PrivateLayoutV2
              exact
              path='/admin/apartments/servicerequests'
              component={ServiceRequests}
            />
            <PrivateLayoutV2
              exact
              path='/admin/apartments/servicerequests/new'
              component={NewServiceRequest}
            />
            <PrivateLayoutV2
              exact
              path='/service-requests/:serviceRequestId'
              component={ServiceRequestDetail}
            />
            <PrivateLayoutV2
              exact
              path='/service-request/:serviceRequestId'
              component={ServiceRequestDetail}
            />
            <PrivateLayoutV2
              exact
              path='/admin/apartments/import'
              component={ApartmentImportPreview}
            />
            <PrivateLayoutV2
              exact
              path='/admin/users/:organisationId/:userId'
              component={UserDetail}
            />
            <PrivateLayoutV2
              exact
              path='/admin/users/success/:organisationId/:userId'
              component={SuccessAddUser}
            />
            <PrivateLayoutV2 exact path='/admin/devices' component={Devices} />
            <PrivateLayoutV2
              exact
              path='/admin/devices/:id'
              component={DeviceDetail}
            />
            <PrivateLayoutV2
              exact
              path='/admin/devices/:orgId/locks/:id'
              component={LockDetail}
            />
            <PrivateLayoutV2
              exact
              path='/admin/kee-history'
              component={KeeHistory}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/admin/apartments/users/:apartmentId'
              component={ApartmentUsers}
            />
            <PrivateLayoutV2
              exact
              path='/admin/:part1(accessgroups)/:part2?/:part3?'
              component={Admin}
              scope={OrganisationServices.KEE}
            />
            <PrivateLayoutV2
              exact
              path='/admin/:part1(partners|customers|unbound|users|servicedesk|apartments)?/:part2?/:part3?'
              component={Admin}
            />
            <PrivateLayoutV2
              exact
              path='/admin/:organisationId'
              component={AdminDetail}
            />
            <PrivateLayoutV2
              exact
              path='/my-organisation'
              component={MyOrganisation}
            />
            <PrivateLayoutV2
              exact
              path='/organisations/:id'
              component={MyOrganisation}
            />
            <PrivateLayoutV2
              exact
              path='/my-keys'
              component={MyKeys}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/booking/admin'
              component={Booking}
              scope={OrganisationServices.BOOKING}
            />
            <PrivateLayoutV2
              exact
              path='/booking'
              component={Booking}
              scope={OrganisationServices.BOOKING}
            />
            <PrivateLayoutV2
              exact
              path='/my-keys/receipt-request'
              component={KeyReceiptAndInventoryRequest}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/my-keys/inventoryapproval-request'
              component={KeyReceiptAndInventoryRequest}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/select-organisation'
              component={SelectOrganisation}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys'
              component={Keys}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/settings'
              component={KeySettings}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/systems'
              component={LockSystems}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/systems/:lockSystemId'
              component={KeysList}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/systems/:lockSystemId/keys/:keyId'
              component={KeyDetails}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/list'
              component={KeysList}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/list/import'
              component={KeyImportPreview}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/list/:keyId'
              component={KeyDetails}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/list/new'
              component={KeyDetails}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/keyrings'
              component={KeyRingsList}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/keyrings/:keyRingId'
              component={KeyRingKeys}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/repositories/:repositoryId/keys/:keyId'
              component={KeyDetails}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/repositories/:repositoryId'
              component={KeysList}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/repositories'
              component={Repositories}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/receipt-templates/new'
              component={ReceiptTemplatesDetails}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/receipt-templates/:id'
              component={ReceiptTemplatesDetails}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/receipt-templates'
              component={ReceiptTemplates}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/inventory'
              component={Inventory}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/inventory/new'
              component={NewKeyInventoryView}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/inventory/:inventoryId'
              component={KeyInventoryDetailView}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/inventory/:inventoryId/manual'
              component={KeyInventoryManualVerificationView}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/guides/return'
              component={ReturnKeys}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/keys/guides/loan/:step'
              component={KeyLoanGuide}
              scope={OrganisationServices.KEY_MANAGEMENT}
            />
            <PrivateLayoutV2
              exact
              path='/:type(orders)'
              component={Cases}
              scope={OrganisationServices.KEY_ORDERS}
            />
            <PrivateLayoutV2
              exact
              path='/orders/new'
              component={NewOrder}
              scope={OrganisationServices.KEY_ORDERS}
            />
            <PrivateLayoutV2
              exact
              path='/orders/new/details'
              component={PaymentDetails}
              scope={OrganisationServices.KEY_ORDERS}
            />
            <PrivateLayoutV2
              exact
              path='/orders/:keyId/new'
              component={NewOrder}
              scope={OrganisationServices.KEY_ORDERS}
            />
            <PrivateLayoutV2
              exact
              path='/orders/checkout'
              component={OrderCheckout}
              scope={OrganisationServices.KEY_ORDERS}
            />
            <PrivateLayoutV2
              exact
              path='/orders/:id'
              component={CaseDetails}
              scope={OrganisationServices.KEY_ORDERS}
            />
            <PrivateLayoutV2 exact path='/id-control' component={IdControl} />
            <PrivateLayoutV2
              exact
              path='/id-control/:referenceId'
              component={ControlDetails}
            />
            <PrivateLayoutV2 exact path='/reports' component={Reports} />
            <PrivateLayoutV2 exact path='/contact' component={Contact} />
            <Route exact path='/register/:token' component={Register} />
            <PublicLayout exact path='/reset/:email?' component={Reset} />
            <PublicLayout exact path='/recover/:token' component={Recover} />
            <PublicLayout path='/login' component={Login} />
            <PublicLayout
              path='/activate-account'
              component={ActivateAccount}
            />
            <PublicLayout
              path='/key-loan/:token'
              component={PublicTemporaryKeyLoan}
            />
            <Route path='/logout' component={Logout} />
            {/* Onboarding flow */}
            <PublicLayout
              exact
              path='/onboarding'
              component={BlueprintWizard}
            />
            <PublicLayout
              exact
              path='/onboarding/properties/:propertyId/blueprints/:blueprintId'
              component={BlueprintTool}
            />
            <PublicLayout
              exact
              path='/onboarding/properties/:propertyId/blueprints/:blueprintId/register'
              component={RegisterOnboarding}
            />
            <PublicLayout
              exact
              path='/register-organisation'
              component={RegisterOnboarding}
            />
            <PublicLayout
              exact
              path='/onboarding/properties/:propertyId/blueprints/:blueprintId/overview'
              component={BlueprintListView}
            />
            <Route path='*' component={NotFound} />
          </Switch>
        </LastLocationProvider>
      </Suspense>
    </ConnectedRouter>
  </>
)

export default Routes
