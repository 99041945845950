import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import Theme from '../helpers/Theme'

const styles = {
  root: {
    textDecoration: 'underline',
    color: Theme.Colors.TEXT,
    fontWeight: 700,
    '&:hover': {
      color: Theme.Colors.TEXT
    }
  }
}

const Link = props => {
  const { classes, to, blank, onClick, children, ...otherProps } = props
  return (
    <a
      className={classes.root}
      href={to || '#'}
      target={blank ? '_blank' : undefined}
      rel={blank ? 'noopener noreferrer' : undefined}
      onClick={onClick}
      {...otherProps}>
      {children}
    </a>
  )
}

Link.propTypes = {
  classes: PropTypes.object.isRequired,
  to: PropTypes.string,
  blank: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.node,
    PropTypes.string
  ]).isRequired
}

Link.defaultProps = {
  to: null,
  blank: false,
  onClick: undefined
}

const LinkWithStyles = injectSheet(styles)(Link)
export default LinkWithStyles
