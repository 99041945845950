import React from 'react'
import PropTypes from 'prop-types'
import injectSheet from 'react-jss'
import clx from 'classnames'
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon'
import axios from 'axios'
import { getSelectedOrg } from 'src/utils/helpers'
import { UserRoles } from '../utils/constants'
import Button from './Button'
import useWindowDimensions from '../utils/useWindowDimensions'
import useLogotype from '../utils/useLogotype'
import Link from './Link'

const styles = {
  rootDesktop: {
    height: 80,
    zIndex: 1010,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0
  },
  bar: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F8F8F8',
    height: 80,
    boxShadow:
      '0 3px 3px -2px rgba(0,0,0,0.2), 0 3px 4px 0 rgba(0,0,0,0.14), 0 1px 8px 0 rgba(0,0,0,0.12)'
  },
  logoContainer: {
    height: 120,
    width: 115,
    marginLeft: 49,
    marginRight: 'auto'
  },
  customLogoContainer: {
    width: 160,
    height: 80,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: '100%'
  },
  customLogo: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
    filter: 'grayscale(100%)'
  },
  logoUpperRectangle: {
    height: 23,
    backgroundColor: '#1B1C1A'
  },
  logoLowerRectangle: {
    height: 93,
    marginTop: 3,
    backgroundColor: '#1B1C1A'
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  callUsMessageContainer: {
    alignSelf: 'center',
    paddingRight: 16,
    fontSize: '1.25rem',
    color: 'var(--color-middle-grey)',
    '& strong': {
      color: 'var(--color-text)'
    }
  },
  userDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: 18
  },
  logoutBtn: {
    marginLeft: 30,
    marginRight: 50
  },
  accountIconContainer: {
    borderRadius: 500,
    width: 48,
    height: 48,
    transition: 'transform 200ms linear',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'var(--color-primary-tone-3)'
    }
  }
}

const Header = props => {
  const {
    classes,
    user,
    defaultLogotype,
    handleLogout,
    handleReverseImpersonate,
    left,
    history,
    setShowChangeOrganisationModal
  } = props

  const { isMobile } = useWindowDimensions()
  const { logotype, getSrc } = useLogotype(defaultLogotype ? null : user)

  if (isMobile) {
    return <div />
  }

  const isImpersonating = user && user.impersonater

  return (
    <>
      <div className={classes.rootDesktop} style={{ left }}>
        <div className={classes.bar}>
          {/* Use default logotype */}
          {!logotype.id && (
            <div className={classes.logoContainer}>
              <Link to={global._('Header.LogoLinkTo')} blank>
                <img
                  src={getSrc()}
                  className={classes.logo}
                  alt={user && user.organisation.name}
                />
              </Link>
            </div>
          )}
          {logotype.id && (
            <div
              className={clx(
                classes.logoContainer,
                classes.customLogoContainer
              )}
            >
              <img
                src={getSrc()}
                className={clx(classes.logo, classes.customLogo)}
                alt={user && user.organisation.name}
              />
            </div>
          )}
          {history?.location?.pathname === '/booking/admin' && (
            <Button
              variant='outlined'
              style={{ height: 40, marginTop: 14 }}
              onClick={() => {
                ;(async () => {
                  const selectedOrg = getSelectedOrg(user)
                  const { data } = await axios.get(
                    `/v1/organisations/${selectedOrg.id}/users`
                  )
                  const usersWithApartments = data.filter(
                    u => u.apartmentNumber
                  )
                  let csv = 'data:text/csv;charset=utf-8,'
                  usersWithApartments.forEach(u => {
                    csv += `${u.apartmentNumber};${u.name};${u.apartmentAddress}\n`
                  })
                  csv = csv.trim()
                  const encodedURI = encodeURI(csv)
                  const link = document.createElement('a')
                  link.setAttribute('href', encodedURI)
                  link.setAttribute('download', 'export.csv')
                  document.body.appendChild(link) // Required for FF
                  link.click()
                  document.body.removeChild(link)
                })()
              }}
            >
              {global._('Header.ExportUsers')}
            </Button>
          )}
          {user && !user.roles.includes(UserRoles.Guest) && (
            <div className={classes.userContainer}>
              <Tooltip title={global._('Header.MyAccount')} placement='bottom'>
                <div
                  className={classes.accountIconContainer}
                  onClick={() => history.push('/myaccount')}
                  onKeyPress={() => history.push('/myaccount')}
                  role='button'
                  tabIndex='0'
                >
                  <Icon
                    className={classes.accountIcon}
                    style={{ fontSize: 48 }}
                  >
                    account_circle
                  </Icon>
                </div>
              </Tooltip>
              <div className={classes.userDetailContainer}>
                <span>
                  <strong>{user.name}</strong>
                </span>
                <span>{user.organisation.name}</span>
              </div>
              {setShowChangeOrganisationModal && (
                <Button
                  variant='outlined'
                  style={{ marginLeft: 20 }}
                  onClick={() => setShowChangeOrganisationModal(true)}
                >
                  {global._('Common.ChangeOrganisation')}
                </Button>
              )}
              <Button
                data-cy='button-logout'
                variant={isImpersonating ? 'primary' : 'secondary'}
                style={{ marginLeft: 20, marginRight: 50 }}
                onClick={
                  isImpersonating ? handleReverseImpersonate : handleLogout
                }
              >
                {global._(
                  isImpersonating ? 'Common.StopImpersonating' : 'Common.Logout'
                )}
              </Button>
            </div>
          )}
          {(!user || (user && user.roles.includes(UserRoles.Guest))) && (
            <div className={classes.callUsMessageContainer}>
              <span>
                {global._('Header.CallUsText')}
                <strong>
                  {global._(
                    'Header.CallUsPhone',
                    null,
                    global.getLanguageByDomainExtension()
                  )}
                </strong>
              </span>
            </div>
          )}
        </div>
      </div>
      {/* Empty space where header is */}
      <div style={{ width: '100%', height: 80 }} />
    </>
  )
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  setShowChangeOrganisationModal: PropTypes.func,
  history: PropTypes.object,
  user: PropTypes.object,
  handleLogout: PropTypes.func,
  handleReverseImpersonate: PropTypes.func,
  left: PropTypes.number
}

Header.defaultProps = {
  user: null,
  handleLogout: null,
  handleReverseImpersonate: null,
  history: null,
  left: 0,
  setShowChangeOrganisationModal: undefined
}

const HeaderWithStyles = injectSheet(styles)(Header)
export default HeaderWithStyles
