/*
 * Fetches stored logotype if it exists
 * - uses default logotype if it doesn't exist
 * - if logged in user is part of a SystemAdmin or Unbound organisation it uses the default logotype
 * - checks diff of logotypeFileId vs stored id once per page load
 *
 */
import { useState, useEffect } from 'react'
import axios from 'axios'
import { OrganisationTypes, UserRoles } from './constants'

export const STORAGE_KEY_LOGOTYPE = '@header/logotype'
export const LOGOTYPE_CHANGE_EVENT = '@prosero/event/header/logotype/change'
const DEFAULT_LOGOTYPE_SRC = '/logos/HeaderLogo-230.png'
const defaultLogotype = {
  id: null
}

export default (user, defaultSrc = DEFAULT_LOGOTYPE_SRC) => {
  const [logotype, setLogotype] = useState(defaultLogotype)

  const getSrc = () => {
    if (user && logotype && logotype.id) {
      return `data:${logotype.type};base64,${logotype.data}`
    }
    return defaultSrc
  }
  const getLogotype = async diff => {
    if (
      !user ||
      !user.organisation ||
      (user && user.roles && user.roles.includes(UserRoles.Guest))
    ) {
      return
    }
    try {
      const organisationId = user.organisation.partnerId || user.organisation.id
      const { data: logotypeData } = await axios.get(
        `/v1/organisations/${organisationId}/logotype${
          diff ? '?diff=true' : ''
        }`
      )
      if (diff && logotype && logotypeData) {
        if (logotypeData.id === null) {
          localStorage.removeItem(STORAGE_KEY_LOGOTYPE)
          setLogotype(defaultLogotype)
        } else if (logotype.id !== logotypeData.id) {
          getLogotype(false)
        }
      } else if (!diff) {
        localStorage.setItem(STORAGE_KEY_LOGOTYPE, JSON.stringify(logotypeData))
        setLogotype(logotypeData)
      }
    } catch (e) {
      localStorage.removeItem(STORAGE_KEY_LOGOTYPE)
      setLogotype(defaultLogotype)
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  const getStoredLogotype = () => {
    if (!user || !user.organisation) {
      return defaultLogotype
    }

    const { type } = user.organisation
    if (
      type !== OrganisationTypes.SystemAdmin &&
      type !== OrganisationTypes.Unbound
    ) {
      let storedLogotype
      try {
        const logotypeJson = localStorage.getItem(STORAGE_KEY_LOGOTYPE)
        if (logotypeJson) {
          storedLogotype = JSON.parse(logotypeJson)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)
      }
      if (storedLogotype) {
        return storedLogotype
      }
    }

    return defaultLogotype
  }

  /* Watch for changes */
  useEffect(() => {
    const storageChangeEvent = () => {
      setLogotype(getStoredLogotype())
    }
    const logotypeChangeEvent = () => {
      getLogotype(true)
    }
    window.addEventListener('storage', storageChangeEvent, false)
    window.addEventListener(LOGOTYPE_CHANGE_EVENT, logotypeChangeEvent, false)
    return () => {
      window.removeEventListener('storage', storageChangeEvent, false)
      window.removeEventListener(
        LOGOTYPE_CHANGE_EVENT,
        logotypeChangeEvent,
        false
      )
    }
  }, [user, logotype])

  /* Initial load of stored logotype */
  useEffect(() => {
    setLogotype(getStoredLogotype())
    /* Check diff */
    getLogotype(true)
  }, [user])

  return {
    logotype,
    getSrc
  }
}
